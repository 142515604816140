

import { Vue, Component } from 'vue-property-decorator'
import { Product } from '../../types/supplier'

@Component
export default class SupplierProductDetail extends Vue {
  productId = ''

  detail: Product = {
    productName: '',
    productType: '',
    productDesc: '',
    productPrice: '',
    supplierId: '',
    resourceList: []
  }

  created () {
    this.productId = this.$route.query.productId as string
    this.loadData()
  }

  loadData () {
    this.$axios.get<Product>(this.$apis.supplier.getProductById, {
      productId: this.productId
    }).then(res => {
      this.detail = res
    })
  }
}
